<template>
  <div @click="isBlock = falsae">
    <LiefengContent>
      <template #title>上线社区管理</template>
      <template #toolsbarRight>
        <Form  :label-colon="true" :inline="true" class="search">
            <FormItem>
              <Button style="margin-right:10px" :type="primary == ''?'primary':'default'" @click="selectBtn('')">全部</Button>
              <Button style="margin-right:10px" :type="primary == '1'?'primary':'default'" @click="selectBtn('1')">示范社区</Button>
              <Button style="margin-right:10px" :type="primary == '2'?'primary':'default'" @click="selectBtn('2')">试用社区</Button>
              <Button style="margin-right:10px" :type="primary == '3'?'primary':'default'" @click="selectBtn('3')">非试用社区</Button>
            </FormItem>
            <FormItem>
              <LiefengCascaderOne
                :isTrue="isTrue"
                class="cascader"
                :cityOper="cityOper"
                @changeLocal="changeLocal"
              ></LiefengCascaderOne>
            </FormItem>
            <FormItem >
               <DatePicker
                v-model="dateTime"
                format="yyyy/MM/dd"
                type="datetime"
                placement="bottom-end"
                placeholder="选择时间区域"
                style="width: 250px;margin-right:10px"
                @on-change="changeTime"
              ></DatePicker>
            </FormItem>
            <Button style="margin-right:10px" type="primary" @click="search">查找</Button>
            <Button style="margin-right:10px" type="info" @click="reset">重置</Button>
            <!-- <Button style="margin-right:10px" type="warning" icon="ios-cloud-upload-outline" @click="importHouse">导入户数</Button> -->
<!--            <Button style="margin-right:10px" type="success" @click="batchBtn">批量上线</Button>-->
            <Button style="margin-right:10px" type="error" @click="dowunModal" icon="ios-download-outline">导出</Button>
        </Form>
       
        <!-- <Button :type="primary == '3'?'primary':'default'" @click="selectBtn('3')">暂停上线</Button> -->
        
       
        
      </template>
      <template #contentArea>
        <div class="container">
          <LiefengTable
            v-if="isShow"
            ref='lftableo'
            :tableData="tableData"
            :talbeColumns="talbeColumns"
            :loading="loading"
            :pageSize="pageSize"
            :pagesizeOpts="[20, 30, 50, 100, 200]"
            :total="total"
            :curPage="page"
            @hadlePageSize="hadlePageSize"
            @tableSelect="tableSelect"
            :fixTable="true"
          ></LiefengTable>
        </div>
      </template>
    </LiefengContent>
    <!--批量上线-->
    <LiefengModal
      title="修改"
      :fullscreen="false"
      :value="batchModal"
      @input="batchModallFn"
      class="group"
    >
      <template v-slot:contentarea>
        <div style="padding:20px">
        <span style="margin-right:10px">是否上线:</span>
          <i-switch true-value="1" false-value="2" v-model="status" disabled/>
        </div>
        <div style="padding:20px" v-if="status == '1'">
          <span style="margin-right:10px"><span style="color: red">* </span>上线时间:</span>

          <DatePicker
            :options="options"
            type="date"
            placeholder="请选择上线时间"
            v-model="batchDate"
            style="width:300px"
            format="yyyy/MM/dd"
            @on-change="changeLineDate"
          ></DatePicker>
        </div>
        <div style="padding:20px">
        <span style="margin-right:10px">上线状态:</span>
          <Select v-model="orgOnlineStatus" style="width: 50%" :disabled="statusDisabled">
            <Option value="1">示范社区</Option>
            <Option value="2">试用社区</Option>
            <!-- <Option value="3">非试用社区</Option> -->
          </Select>
        </div>
        <div style="padding:20px" v-if="orgOnlineStatus == 1">
          <span style="margin-right:10px">示范级别:</span>
          <Select v-model="orgOnlineType" style="width: 50%">
            <Option value="1">市级示范社区</Option>
            <Option value="2">区级示范社区</Option>
            <Option value="3">街镇级示范社区</Option>
          </Select>
        </div>
      </template>
      <template v-slot:toolsbar>
        <Button
          type="info"
          style="margin-right: 10px"
          @click="closeInfoWindow()"
        >取消</Button>
        <Button type="primary" @click="batchSave">保存</Button>
      </template>
    </LiefengModal>

    <!-- 导入excel弹窗 -->
    <LiefengModal title="导入题库数据" :value="excelStatus" @input="exportModalFn">
      <template v-slot:contentarea>
        <Upload
          ref="excelUpload"
          type="drag"
          :action="excelAction"
          name="file"
          :max-size="10240"
          :show-upload-list="true"
          :before-upload="beforeExcelUpload"
          :on-success="myExcel"
          :data="uploadData"
          :default-file-list="fileList"
          :format="['xls', 'xlsx', 'xlsb', 'xlsm', 'xlst']"
          :on-format-error="uploadFormatError"
          :on-exceeded-size="uploadSizeError"
        >
          <div style="padding: 20px 0; text-align: center; width: 100%">
            <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
            <p>点击上传，或将文件拖拽到此处</p>
          </div>
        </Upload>
        <div v-if="textStatus">当前选择的文件名称:{{ excelFile.name }}</div>
        <!-- <div v-html="errorContent"></div> -->
        <div style="text-align: center; margin: 20px 0">
          <Button type="info" @click="exportModalFn(false)" style="margin-right:20px">取消</Button>
          <Button type="primary" @click="submitExcel">确认导入</button>
        </div>
      </template>
    </LiefengModal>
  </div>
</template>

<script>
//@route('/communityindex')
import LiefengContent from "@/components/LiefengContent3";
import LiefengTable from "@/components/LiefengTable";
import LiefengCascaderOne from "./children/LiefengCascaderOne";
import LiefengModal from "@/components/LiefengModal";
export default {
  data() {
    return {
      isBlock:false,
      searchForm: {
        startTime: "",
        endTime: ""
      },
      cityOper: { city: "", region: "", stree: "", project: "" },
      // 表格数据
      tableData: [],
      talbeColumns: [
        {
          type: "selection",
          width: 60,
          align: "center"
        },
        {
          title: "社区名称",
          key: "orgName",
          width: 140,
          align: "center"
        },
        // {
        //   title: "状态",
        //   key: "status",
        //   width: 140,
        //   align: "center",
        //   render: (h, params) => {
        //     return h(
        //       "div",
        //       {
        //         style: {
        //           color: params.row.status == 1 ? "green" : "black"
        //         }
        //       },
        //       params.row.status != 1
        //         ? "未上线"
        //         : '已上线'
        //     );
        //   }
        // },
        {
          title: "所属区",
          key: "zone",
          width: 140,
          align: "center"
        },
        {
          title: "所属街道",
          key: "street",
          width: 170,
          align: "center"
        },
        {
          title: "应有总户数",
          width: 140,
          align: "center",
          renderHeader: (h,params)=>{
            return h('div',[
                h('strong','应有总户数'),
                h('Icon',{
                    props:{
                        type: 'md-help-circle'
                    },
                    style: {
                        marginLeft: '5px',
                        cursor: 'pointer'
                        
                    },
                    on:{
                      click:e=>{
                        e.stopPropagation();
                        this.isBlock = !this.isBlock;
                      }
                    }
                }),
                h('div',{
                  style:{
                    marginTop:'15px',
                    marginLeft:'-80px',
                    // transform:'translateY(150)',
                    // transform:'translateX(300)',
                    width:'250px',
                    display:this.isBlock?'block':'none',
                    position:'fixed',
                    background:'white',
                    border:'1px solid #ccc',
                    textAlign:'left',
                    padding:'10px',
                    transition:'all 1s',
                    cursor:'pointer',
                    userSelect:'none',
                    zIndex:9999
                  },
                  on:{
                      click:e=>{
                        e.stopPropagation();
                      }
                    }
                },[
                  <div>
                  <p style="text-align:center">等于社区架构房号数</p>
                  </div>
                  ])
            ])
          },
          render: (h, params) => {
            if (params.row.orgOnlineStatus == 3) {
              return h("div", {}, "")
            } else{
              return h("InputNumber", {
              props: {
                value: params.row.shouldHouse,
                disabled: params.row.status == "1" ? false : true,
                min:"1"
              },
              on: {
                "on-change": event => {
                  this.changeInput(event, params.row.orgId);
                }
              }
            });
            }
            
          }
        },
        // {
        //   title: "已初始化户数",
        //   key: "initializeHouse",
        //   width: 140,
        //   align: "center"
        // },
        // {
        //   title: "已认证居民户数",
        //   key: "verifiedHouse",
        //   minWidth: 140,
        //   align: "center"
        // },
        {
          title: "上线状态",
          width: 140,
          align: "center",
          render: (h, params) => {
            if (params.row.orgOnlineStatus == 1) {
              return h("div", {}, "示范社区")
            } else if (params.row.orgOnlineStatus == 2) {
              return h("div", {}, "试用社区")
            } else if (params.row.orgOnlineStatus == 3) {
              return h("div", {}, "非试用社区")
            } else {
              return h("div", {}, "")
            }
          }
        },
        {
          title: "示范级别",
          width: 140,
          align: "center",
          render: (h, params) => {
            if (params.row.orgOnlineType == 1) {
              return h("div", {}, "市级示范社区")
            } else if (params.row.orgOnlineType == 2) {
              return h("div", {}, "区级示范社区")
            } else if (params.row.orgOnlineType == 3) {
              return h("div", {}, "街镇级示范社区")
            } else {
              return h("div", {}, "")
            }
          }
        },
        {
          title: "上线时间",
          key: "onlineDate",
          minWidth: 140,
          align: "center",
          render: (h, params) => {
            if (params.row.orgOnlineStatus == 3) {
              return h("div", {}, "")
            } else{
              return h("div", {}, params.row.onlineDate)
            }
          }
        },
        // {
        //   title: "成就徽章",
        //   key: "achievementBadge",
        //   minWidth: 140,
        //   align: "center"
        // },
        {
          title: "操作",
          width: 100,
          align: "center",
          render: (h, params) => {
            return h(
              "div",
              {
                style: {
                  display: "flex",
                  justifyContent: "center"
                }
              },
              [
                h(
                  "Button",
                  {
                    style: {
                      marginRight: "10px",
                      // display: params.row.status != "3" ? "block" : "none"
                    },
                    props: {
                      type: "info"
                    },
                    nativeOn: {
                      click: () => {
                          this.updateOnline(params.row.orgCode, params.row.orgId);
                      }
                    }
                  },
                    "修改"
                )
                // h(
                //   "Button",
                //   {
                //     props: {
                //       type: "warning"
                //     }
                //   },
                //   "查看成就"
                // )
              ],

              "修改"
            );
          }
        }
      ],
      loading: false,
      pageSize: 20,
      total: 0,
      page: 1,
      primary: "",
      curPage: 0,
      //   批量上线
      batchModal: false,
      cancelModalTip: false,
      batchDate: "",
      orgOnlineStatus: "",
      orgOnlineType: "",
      status: "",
      timeou: null, //存放定时器
      isBatchOrNo: false,
      selectList: "", //存放选中的表格数据
      options: {
        disabledDate(date) {
          return date && date.valueOf() > new Date() ;
        }
      },
      rowOrgId: "",
      dateTime: "",
      isTrue: false, //是否重置
      isShow: true, //是否强制刷新

      excelStatus: false,
      //excel文件数据
      excelFile: null,
      //修改data
      titleData: {},
      uploadData: {},
      fileList: [],
      //导入文字显示标识
      textStatus: false,
      excelAction: "",
      //excel导入错误信息
      errorContent: null,

      //非试用社区禁止更改状态
      statusDisabled:'',
    };
  },
  methods: {
    //   方法部分
    // 导入的取消按钮
    exportModalFn(status) {
      this.excelStatus = status;
      
    },
    importHouse() {
      this.fileList = [];
      this.textStatus = false;
      this.excelStatus = true;
    },
    //上传之前
    beforeExcelUpload(file) {
      this.excelAction =
        window.vue.getProxy()["/gx"].target +
        "/upload/orgOnline/importShouldHouse";
      this.uploadData.oemCode = parent.vue.oemInfo.oemCode;
      this.excelFile = file;
      this.textStatus = true;
      return false;
    },
    //导入excel格式错误
    uploadFormatError(file) {
      this.$Notice.warning({
        title: "文件格式不正确",
        desc:
          "文件" +
          file.name +
          "格式不正确，请上传xls,xlsx,xlsb,xlsm或xlst格式的文件"
      });
    },
    //导入excel文件大小超出限制
    uploadSizeError(file) {
      this.$Notice.error({
        title: "导入失败",
        desc: "文件 " + file.name + " 大小已超出限制，请控制在10M以内"
      });
    },
    //excel文件上传成功后执行
    myExcel(res) {
      if (res.code == 200 && res.dataList.length == 0) {
        this.$Message.success({
          background: true,
          content: "导入成功！"
        });
        this.textStatus = false;
        let params = {
          page: this.curPage,
          pageSize: this.pageSize
        };
        this.getList(params);
        this.excelStatus = false;
     
      } else {
        this.fileList = [];
        this.$Message.error({
          background: true,
          content: "导入失败,请按照模板格式填写数据！"
        });
        res.dataList.map((item, index) => {
          this.errorContent += `<div style="color:red">${
            item.number != null ? "第" + (item.number + 4) + "行:" : ""
          }
          ${item.errorCode}</div>`;
        });
      }
    },
    //确认导入
    submitExcel() {
      if (this.excelFile) {
        this.$refs.excelUpload.post(this.excelFile);
      } else {
        this.$Message.error({
          background: true,
          content: "请先选择文件"
        });
      }
    },
    // 点击导出功能
    exportExcel() {
       this.$Modal.confirm({
          title: "温馨提示",
          content: "您正在关闭此窗口，信息将不会保存，是否继续",
          onOk: () => {
            this.exportData()
          }
        });
    },
    // 选择时间回调事件
    changeLineDate(val) {
      this.batchDate = this.$core.formatDate(
        new Date(this.batchDate),
        "yyyy-MM-dd"
      );
    },
    //点击搜索方法
    search() {
      console.log(this.cityOper);
      let lastCode = "";
      if (this.cityOper.city && this.cityOper.city != "") {
        lastCode = this.cityOper.city.split("-").pop();
        if (this.cityOper.region && this.cityOper.region != "") {
          lastCode = this.cityOper.region.split("-").pop();
          if (this.cityOper.stree && this.cityOper.stree != "") {
            lastCode = this.cityOper.stree.split("-").pop();
            if (this.cityOper.project && this.cityOper.project != "") {
              lastCode = this.cityOper.project.split("-").pop();
            }
          }
        }
      }
      let data = {
        code: lastCode,
        page: 1,
        pageSize: 20
      };
      this.getList(data);
    },
    // 改变页数方法
    hadlePageSize(data) {
      // console.log("分页", data);
      // return;
      this.getList(data);
    },
    // 表格选中的项
    tableSelect(val) {
      let List = [];
      if (val) {
        val.map(item => {
          List.push(item.orgId);
        });
      }
      this.selectList = List.join(",");
    },
    // 选择时间后的回调事件
    changeTime(val) {
      if(val && val.length){
        if(val[0] != ''){
          this.searchForm.startTime = this.$core.formatDate(
            new Date(val[0]),
            "yyyy-MM-dd"
          );
        }else{
           this.searchForm.startTime = ''
        }
        if(val[1] != ''){
           this.searchForm.endTime = this.$core.formatDate(
            new Date(val[1]),
            "yyyy-MM-dd"
          );
        }else{
          this.searchForm.endTime = ''
        }
      }else{
        this.searchForm.startTime = ''
        this.searchForm.endTime = ''
      }
    },
    // 点击搜索框全部的按钮
    selectBtn(status) {
      this.primary = status;
      this.page = 1;
      this.pageSize = 20;
      let data = {
        page: this.page,
        pageSize: this.pageSize
      };
      this.isShow = false;
      this.$nextTick(() => {
        this.isShow = true;
        this.hadlePageSize(data);
      });
    },
    // 点击批量上线按钮
    batchBtn() {
      if (this.selectList == "") {
        this.$Message.warning({
          content: "请选择社区",
          background: true
        });
        return;
      } else {
        this.batchModal = true;
        this.isBatchOrNo = true;
      }
    },
    // 修改
    updateOnline(orgCode, orgId) {
      this.$get('/gx/pc/organization/selectOrgOnlineByOrgCode',{
        orgCode:orgCode
      }).then(res => {
        if(res.code == 200){
          this.statusDisabled = res.data.orgOnlineStatus == 3?true:false
          this.status = res.data.status
          this.orgOnlineStatus = res.data.orgOnlineStatus
          this.orgOnlineType = res.data.orgOnlineType
          if (res.data.onlineDate) {
            this.batchDate = this.$core.formatDate(new Date(res.data.onlineDate),"yyyy-MM-dd hh:mm:ss")
          }
          console.log(this.batchDate)
        }
      })
      this.rowOrgId = orgId;
      this.batchModal = true;
      this.isBatchOrNo = false;
    },

    closeInfoWindow(){
      this.batchModal = false;
    },

    // 关闭批量上线模态框事件
    batchModallFn(status) {
      if (!status && this.cancelModalTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "您正在关闭此窗口，信息将不会保存，是否继续",
          onOk: () => {
            this.batchModal = status;
            this.batchDate = "";
          }
        });
      } else {
        this.batchDate = "";
        this.batchModal = status;
      }
    },
    // 批量上线模态框保存按钮
    batchSave() {
      if ((this.status == 1 && this.batchDate) || this.status == 2) {
        if (this.isBatchOrNo) {
          // 批量上线走这
          let data = {
            status: 1,
            selectList: this.selectList
          };
          this.updateOnlineStatus(data);
        } else {
          // 单个上线走这

          let data = {
            status: status,
            selectList: this.rowOrgId,
          };
          this.updateOneStatus(data);
        }
      } else {
        this.$Message.warning({
          content: "上线时间不能为空",
          background: true
        });
        return;
      }
    },

    // 点击任意组件返回的回调函数
    changeLocal(val) {
      this.cityOper.city = val.city;
      this.cityOper.region = val.region;
      this.cityOper.stree = val.stree;
      this.cityOper.project = val.project;
    },
    // 点击重置按钮
    reset() {
      this.isTrue = true;
      this.cityOper.city = "";
      this.cityOper.region = "";
      this.cityOper.stree = "";
      this.cityOper.project = "";
      this.searchForm.startTime = "";
      this.searchForm.endTime = "";
      this.dateTime = "";
      this.pageSize = 20;
      this.primary = "";
      this.selectBtn("");
      // let data = {
      //   page: 1,
      //   pageSize: 20
      // };
      // this.getList(data);
    },
    // 点击设置未上线
    noneBatch(orgId) {
      this.$Modal.confirm({
        title: "温馨提示",
        content: "是否继续操作？",
        onOk: () => {
          let data = {
            selectList: orgId,
            status: 2,
            onlineDate: ""
          };
          this.updateOneStatus(data);
        }
      });
    },

    // input框change事件
    changeInput(value, orgId) {
      this.debounce(value, 2000, orgId);
    },

    // 封装了一个防抖函数
    debounce(func, wait, orgId) {
      if (this.timeou) clearTimeout(this.timeou);
      this.timeou = setTimeout(() => {
        if (func != "") {
          let data = {
            orgId: orgId,
            shouldHouse: func
          };
          this.updateData(data);
        }
      }, wait);
    },
    getNowDate() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      if (month < 10) month = "0" + month;

      if (day < 10) day = "0" + day;

      return (
        year + "-" + month + "-" + day + " " + "00" + "-" + "00" + "-" + "00"
      );
    },
    // 接口部分
    getList(data) {
      this.loading = true;

      let params = {
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        startTime: this.searchForm.startTime?`${this.searchForm.startTime} 00:00:00`:'',
        endTime: this.searchForm.endTime?`${this.searchForm.startTime} 23:59:59`:'',
        orgCode: data.code ? data.code : parent.vue.oemInfo.oemCode,
        staffName: parent.vue.loginInfo.userinfo.realName,
        orgOnlineStatus: this.primary,
        operatorOrgCode: parent.vue.loginInfo.userinfo.orgCode,
        page: data.page,
        pageSize: data.pageSize
      };
      this.$post(
        "/statistic/api/symanage/pc/orgOnline/queryOrgOnlinePage",
        params
      ).then(res => {
        this.tableData = [];
        this.loading = false;
        let data = res.dataList;
        for (var i = 0; i < data.length; i++) {
          if (data[i].status == 1) {
            data[i]._disabled = true;
          }
          if (data[i].onlineDate) {
            data[i].onlineDate = this.$core.formatDate(
              new Date(data[i].onlineDate),
              "yyyy-MM-dd"
            );
          }
        }
        let _this = this
        setTimeout( () => {
          _this.tableData = data;
        },10)
        this.total = res.maxCount;
        this.curPage = res.currentPage;
        this.isTrue = false;
      });
    },
    // 更新数据的方法
    updateData(data) {
      let params = {
        orgId: data.orgId,
        shouldHouse: data.shouldHouse
      };
      this.$post("/gx/pc/organization/updateOrgOnlineHouse", params).then(
        res => {
          if (res.code == 200) {
            this.$Message.success({
              content: "修改成功",
              background: true
            });
            let params = {
              page: this.curPage,
              pageSize: this.pageSize
            };

            this.getList(params);
          } else {
            this.$Message.error({
              content: "修改失败",
              background: true
            });
          }
        }
      );
    },
    // 更新批量上下线接口
    updateOnlineStatus(data) {
      let params = {
        orgIds: data.selectList,
        status: data.status,
        onlineDate: this.batchDate
      };

      this.$post("/gx/pc/organization/updateBatchOrgOnlineStatus", params).then(
        res => {
          if (res.code == 200) {
            this.$Message.success({
              content: "操作成功",
              background: true
            });
            let params = {
              page: this.curPage,
              pageSize: this.pageSize
            };
            this.batchModal = false;
            this.cancelModalTip = false;
            this.getList(params);
          } else {
            this.$Message.error({
              content: "操作失败",
              background: true
            });
          }
        }
      );
    },
    // 单个上下线接口
    updateOneStatus(data) {
      let params = {
        orgId: data.selectList,
        status: this.status,
        onlineDate: this.batchDate?`${this.$core.formatDate( new Date(this.batchDate), "yyyy-MM-dd")} 00:00:00`:'',
        orgOnlineType : this.orgOnlineType,
        orgOnlineStatus : this.orgOnlineStatus
      };
      if (params.status == '2') {
        params.onlineDate = null;
      }
      if (params.orgOnlineStatus != '1') {
        params.orgOnlineType = '';
      }
      this.$post("/gx/pc/organization/updateOrgOnlineStatus", params).then(
        res => {
          if (res.code == 200) {
            this.$Message.success({
              content: "操作成功",
              background: true
            });
            let params = {
              page: this.curPage,
              pageSize: this.pageSize
            };
            this.batchModal = false;
            this.cancelModalTip = false;
            this.getList(params);
          } else {
            this.$Message.error({
              content: "操作失败",
              background: true
            });
          }
        }
      );
    },
    // 导出的接口
    exportData(){
          let link = document.createElement('a');
          link.href = window.vue.getProxy()["/statistic"].target +
          `/api/symanage/pc/orgOnline/export?custGlobalId=${parent.vue.loginInfo.userinfo.custGlobalId}&startTime=${this.searchForm.startTime}&endTime=${this.searchForm.endTime}&orgCode=${parent.vue.oemInfo.oemCode}&staffName=${parent.vue.loginInfo.userinfo.realName}&status=${this.primary}&operatorOrgCode=${parent.vue.loginInfo.userinfo.orgCode}`;
          document.body.appendChild(link);
          link.click();
           document.body.removeChild(link);
         this.$.ajax({
        url:
          window.vue.getProxy()["/statistic"].target +
          "/api/symanage/pc/orgOnline/export",
        type: "GET",
        xhrFields: { responseType: "arraybuffer" },
        headers: {
          // "Content-Type": "application/x-www-form-urlencoded",
          custGlobalId:window.sessionStorage.getItem("custGlobalId"),
          Authorization:window.sessionStorage.getItem('accessToken')
        },
        data: {
              custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
              startTime: this.searchForm.startTime,
              endTime: this.searchForm.endTime,
              orgCode:  parent.vue.oemInfo.oemCode,
              staffName: parent.vue.loginInfo.userinfo.realName,
              status: this.primary,
              operatorOrgCode: parent.vue.loginInfo.userinfo.orgCode,
        },
        success: (res) => {

              const blob = new Blob([res])
              console.log(blob);
              
              var href = window.URL.createObjectURL(blob);
              let link = document.createElement('a')
              link.style.display = 'none';
              link.href = href;
              link.setAttribute('download', "上线社区管理.xls");
              document.body.appendChild(link)
              link.click();
              document.body.removeChild(link);
              window.URL.revokeObjectURL(href);
              return
          },
          error: (XMLHttpResponse, textStatus, errorThrown) => {
          
          console.log("1 异步调用返回失败,XMLHttpResponse.readyState:"+XMLHttpResponse.readyState);
          onsole.log("2 异步调用返回失败,XMLHttpResponse.status:"+XMLHttpResponse.status);
          onsole.log("3 异步调用返回失败,textStatus:"+textStatus);
          onsole.log("4 异步调用返回失败,errorThrown:"+errorThrown);
          this.$Message.error({
            background: true,
            content: "导出失败，请联系管理员处理"
          });
        }
         })
    },
    // 下载的方法
    download(data, name) {
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        })
      );
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", `${name}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      this.$Message.destroy();
    },

    dowunModal() {
      this.$Message.loading({
        content: "正在导出，请稍等...",
        duration: 0
      });
      this.$get('/statistic/api/symanage/pc/orgOnline/export',{
             custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
              startTime: this.searchForm.startTime,
              endTime: this.searchForm.endTime,
              orgCode:  parent.vue.oemInfo.oemCode,
              staffName: parent.vue.loginInfo.userinfo.realName,
              status: this.primary,
              operatorOrgCode: parent.vue.loginInfo.userinfo.orgCode,
      }).then(res=>{
         if(res.code == 200){
                  this.$Modal.remove();
                  if (res.data) {
                    if (
                      res.data.slice(0, 4) == "http" &&
                      res.data.slice(0, 5) !== "https"
                    ) {
                      res.data = res.data.replace("http", "https");
                    }
                  }

                  let link = document.createElement("a");
                  link.href = res.data;
                  document.body.appendChild(link);
                  link.click();
                  link.download = "上线社区管理";
                  document.body.removeChild(link);
                  this.$Message.destroy();
                  this.templateStatus = false
                }else{
                  this.$Modal.remove();
                  this.$Message.success({
                  content: "导出失败",
                  background: true
                });
                }
      })
      // this.$.ajax({
      //   url:
      //     window.vue.getProxy()["/statistic"].target +
      //     "/api/symanage/pc/orgOnline/export",
      //   type: "GET",
      //   xhrFields: { responseType: "arraybuffer" },
      //   headers: {
      //     "Content-Type": "application/x-www-form-urlencoded",
      //     custGlobalId:window.sessionStorage.getItem("custGlobalId"),
      //     Authorization:window.sessionStorage.getItem('accessToken')
      //   },
      //   data: {
      //         custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      //         startTime: this.searchForm.startTime,
      //         endTime: this.searchForm.endTime,
      //         orgCode:  parent.vue.oemInfo.oemCode,
      //         staffName: parent.vue.loginInfo.userinfo.realName,
      //         status: this.primary,
      //         operatorOrgCode: parent.vue.loginInfo.userinfo.orgCode,
      //   },
      //   success: res => {
      //     console.log("获取", res);
      //     // if (res) {
      //     //   try {
      //     //     const blob = new Blob([res.data]);
      //     //     var href = window.URL.createObjectURL(blob);
      //     //     let link = document.createElement("a");
      //     //     link.style.display = "none";
      //     //     link.href = href;
      //     //     link.setAttribute("download", "上线社区管理.xls");
      //     //     document.body.appendChild(link);
      //     //     link.click();
      //     //     document.body.removeChild(link);
      //     //     window.URL.revokeObjectURL(href);
      //     //     this.$Message.destroy();
      //     //   } catch (error) {
      //     //     console.log(error);
      //     //     this.$Message.destroy();
      //     //     this.$Message.error({
      //     //       background: true,
      //     //       content: res.desc
      //     //     });
      //     //   }
      //     // }
      //   },
      //   error: (XMLHttpResponse, textStatus, errorThrown) => {
      //     console.log("1 异步调用返回失败,XMLHttpResponse.readyState:"+XMLHttpResponse.readyState);
      //     console.log("2 异步调用返回失败,XMLHttpResponse.status:"+XMLHttpResponse.status);
      //     console.log("3 异步调用返回失败,textStatus:"+textStatus);
      //     console.log("4 异步调用返回失败,errorThrown:"+errorThrown);
      //     console.log(XMLHttpResponse)
      //     this.$Message.error({
      //       background: true,
      //       content: "导出失败，请联系管理员处理"
      //     });
      //   }
      // });
    },

  },
  created() {
    let data = {
      page: 1,
      pageSize: 20
    };
    this.getList(data);
  },
  components: {
    LiefengContent,
    LiefengTable,
    LiefengCascaderOne,
    LiefengModal
  }
};
</script>

<style scoped lang="less">
// /deep/#toolsbarRight {
//   width: 1000%;
//   .cascader {
//     width: 500px;
//   }
// }
/deep/.ivu-modal-close {
  display: none;
}
/deep/.ivu-date-picker{
  width: 320px;
}
</style>